<template>
 <div>
  <div ref="LastEndFa" class="bgimg">
  <div class="title-text">
   <div style="fontSize:1.2rem;line-height:3rem">// 婚礼倒计时 //</div>
    <div style="line-height:1.5rem">
    <div>公历:2023年5月15日</div>
    <div>农历:三月廿六 星期一</div>
    <div>酒宴地址:丹东市同兴镇福满园农家乐</div>
  </div>
  </div>
  
  <!-- <van-image class="wxz1-cla"  src="./djsb.png" /> -->
  <van-count-down :time="time" class="time-cla">
    <template #default="timeData">
     <div class="djs-fa">
      <span class="djs bgc">{{ timeData.days }}</span>
      <span class="djs">天</span>
      <span class="djs bgc">{{ (timeData.hours<10?'0':'')+timeData.hours }}</span>
      <span class="djs">时</span>
      <span class="djs bgc">{{ (timeData.minutes<10?'0':'')+timeData.minutes }}</span>
      <span class="djs">分</span>
      <span class="djs bgc">{{ (timeData.seconds<10?'0':'')+timeData.seconds }}</span>
      <span class="djs">秒</span>
     </div>
    </template>
  </van-count-down>
 </div>
  <BaiduMap />
  <van-image style="width:90vw;margin:5vw;" src="./he1.JPG" />
  <div class="end-fa">
   <div class="end-title">
    <div>剪辑软件：</div>
    <div style="lineHeight:3rem">BGM：</div>
    <div>监制：</div>
    <div>排版：</div>
    <div style="lineHeight:4.2rem">特别鸣谢：</div>
    </div>
   <div class="end-right">
    <div>必剪、剪映(字幕动画)</div>
    <div>《Sakae In Action》<br>《way back then》</div>
    <div>@新娘</div>
    <div>@新郎</div>
    <div>@小胡(Ps指导&伴郎)<br>@海善(伴郎)<br>@小许(素材支持)</div>
   </div>
   
  </div>
  <van-image style="width:90vw;margin:5vw;" src="./fenge2.gif" />
 </div>
 
</template>

<script>
import BaiduMap from './BaiduMap.vue'
export default {
 components:{BaiduMap},
  props:{
    visible_356:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
     time: new Date('2023-5-16').getTime()-new Date().getTime(),
    }
  },
  mounted(){
  }
}
</script>


<style scoped>
.end-fa{
 line-height: 1.5rem;
 display: flex;
 padding: 1rem;
 width: 100vw;
 box-sizing: border-box;
}
.end-title{
 min-width: 40vw;
 text-align: end;
}
.end-right{
 min-width: 60vw;
 text-align:start;
}
.title-text{
 transform: translateY(5vw);
}
.wxz1-cla{
padding: 0;
margin-top: -5px;
}
.time-cla{
 transform:translateY(13vw);
}
.djs{
 font-size: 1.2rem;
 color:rgb(135, 210, 134);
}
.djs-fa{
 display: flex;
 justify-content: center;
 align-items: flex-end;
}
.bgc{
 color:#fff;
 font-size: 1.8rem;
 font-family: "FakeHope";
 line-height: 2.6rem;
 background-color: rgba(135, 210, 134,0.7);
 /* padding: 0.25rem;
 padding-top: 0.45rem; */
 border-radius: 0 0.5rem 0 0.5rem;
 width:2.2rem;
 height:2.2rem;
}
.bgimg{
 background: url('/public/djsb.png') no-repeat;
 background-size:100vw 100%;
 height: 125vw;
}
</style>