import { render, staticRenderFns } from "./newP2.vue?vue&type=template&id=186af809&scoped=true&"
import script from "./newP2.vue?vue&type=script&lang=js&"
export * from "./newP2.vue?vue&type=script&lang=js&"
import style0 from "./newP2.vue?vue&type=style&index=0&id=186af809&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "186af809",
  null
  
)

export default component.exports