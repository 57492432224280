<template>
 <div class="fa">
  <transition name="van-slide-left">
      <!-- <div v-show="visible_356" class="wxz1-info"></div> -->
      <van-image v-show="visible_356" class="wxz1-info"  src="./xinlangkuang.png" />
   </transition>
   <transition name="van-slide-right">
      <van-image v-show="visible_356" class="wxz1-cla"  src="./wxz1.png" />
   </transition>
 </div>
 
</template>

<script>
export default {
  props:{
    visible_356:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
     
    }
  }
}
</script>


<style scoped>
.fa{
  display: flex;
  align-items: center;
  height: calc(109.5vw);
  border-bottom: 1.5vw solid rgb(255, 241, 210);
}
.wxz1-cla{
  /* object-fit: cover; */
  width: 50vw;
  height: 112vw;
  
}
.wxz1-info{
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
  height: 50vw;
  /* width: 100vw; */
  /* border: 1px solid blue; */
  transform: translateX(11vw);
  margin-left: -10vw;
}
</style>