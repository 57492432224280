<template>
 <!-- Video组件 -->
 <div id="common-video" class="h-100">
   <div :class="{ isShow: control }" class="h-100">
    <!-- :autoplay="muted" -->
     <video
       ref="myVideo"
       :muted="muted"
       :poster="poster"
       :src="src"
       :controls="controls"
       @timeupdate="timeupdate"
       oncontextmenu="return false"
       controlslist="nodownload"
       class="video-box"
       preload="auto"
     ></video>
     <div v-if="full" class="haibao-fa" :class="{ 'fade-out': videoState }">
      <!-- @click="operateVideo(1)" -->
      <img
       src="/haibao.jpg"
       alt=""
       class="haibao"
     />
     <div 
      class="pause-btn"
      :class="{ 'fade-out': videoState }" 
      @click.stop="operateVideo(1)"
      >
      <van-icon name="play" />
     </div>
     </div>
     <div 
      v-if="!full && !videoState"
      class="pause-btn-xn"
      @click.stop="operateVideo(2)"
      >
      <van-icon name="play" />
     </div>
     <div
       v-if="full && videoState"
       class="pause-btn2"
       @click.stop="$refs.myVideo.requestFullscreen()"
       >
       <van-icon name="expand" />
     </div>
   </div>
 </div>
</template>

<script>
export default {
 name: "CommonVideo",
 data() {
   return {
     canplay: false, // 可以播放？
     studyTime: {
       currentTime: 0, // 当前已学时长
       duration: 0 // 总时长
     },
     timer: {}, // 定时器
     pauseTimer: {} // 暂停定时器
   };
 },
 /**
  * @param poster 展示图
  * @param src 视频资源
  * @param controls 是否显示控件
  * @param control 控件控制
  * @param videoData 视频基础数据
  */
 props: {
   poster: {
     type: String,
     required: false,
     default: ""
   },
   src: {
     type: String,
     required: true
   },
   controls: {
     type: Boolean,
     required: false,
     default: false
   },
   full: { // 全屏
     type: Boolean,
     required: false,
     default: false
   },
   once: { // 全屏
     type: Boolean,
     required: false,
     default: false
   },
   fatop: { // 全屏
     type: Boolean,
     required: false,
     default: true
   },
   videoState: {
     type: Boolean,
     required: false,
     default: true
   },
   muted: {
     type: Boolean,
     required: false,
     default: true
   },
   control: {
     type: Boolean,
     required: false,
     default: false
   },
   videoData: {
     type: Object,
     required: true
   }
 },
 mounted() {
   // 监听视频播放
   this.$refs.myVideo.addEventListener("play", () => {
     console.log("video is playing");
   });
   // 监听视频暂停
   this.$refs.myVideo.addEventListener("pause", () => {
     console.log("video is stop");
     if(this.$listeners.stopFn){
      this.$listeners.stopFn(1)
     }
   });
   // 监听视频停止
   this.$refs.myVideo.addEventListener("ended", () => {
     console.log("video is end");
     // this.$emit('update:videoState',false)
     if(this.$listeners.stopEndFn){
      this.$listeners.stopEndFn()
     }
   });
   // 监听视频停止
   this.$refs.myVideo.addEventListener("canplay", () => {
     this.canplay = true
   });
 },
 methods: {
  playOnce(){
   if(this.once && this.$refs.myVideo.paused){
    if(this.fatop){
     this.$toast({
       message: '还可以向下滑动哦',
       icon: './huadongtishi.png',
       duration:5000,
       closeOnClick:true,
       closeOnClickOverlay:true,
     });
    }
    
    this.$refs.myVideo.play();
    this.$emit('update:videoState',true)
    // this.once=false
    this.$emit("update:once",false)
   }
  },
   // 获取当前播放位置
   timeupdate(e) {
     this.studyTime.currentTime = e.target.currentTime;
     this.studyTime.duration = e.target.duration ? e.target.duration : 0;
   },
   operateVideoTime(t=0){ //指定位置播放
     this.$refs.myVideo.currentTime=t;
     this.$refs.myVideo.play();
     // if(e===1){
     //   setTimeout(()=>{
     //   if(!this.studyTime.currentTime){
     //        this.$listeners.stopFn(0)
     //     }
     //  },1500)
     // }
   },
   // 操作视频播放、暂停
   operateVideo(e) {
    if(this.once){
     if(this.fatop){
      this.$toast({
        message: '还可以向下滑动哦',
        icon: './huadongtishi.png',
        duration:5000,
        closeOnClick:true,
        closeOnClickOverlay:true,
      });
     }
    }
    this.$emit("update:once",false)
    if(e===2){ // 新娘出场
     this.$listeners.qqshowFn()
    }
     if (!this.src) {
       this.$message({ message: "暂无视频资源，请查看其他视频！" });
       return false;
     }
     if (this.$refs.myVideo.paused) {
       this.$refs.myVideo.play();
       this.$emit('update:videoState',true)
     } else {
       this.$refs.myVideo.pause();
       this.$emit('update:videoState',false)
     }
   }
 },
 watch: {
   // 监听操作
   // videoData(val) {
   //   const { currentTime, duration } = val;
   //   if (currentTime && duration && currentTime < duration) {
   //     this.hintOperate();
   //   }
   // }
 }
};
</script>

<style lang="less">
#common-video {
 position: relative;
 .video-box {
   box-sizing: border-box;
   border: 0;
   display: block;
   width: 100%;
   height: 100%;
   outline: none !important;
 }
 .isShow {
   //进度条
   video::-webkit-media-controls-timeline {
     display: none;
   }
 }
 video::-webkit-media-controls-play-button {
   visibility: hidden;
 }
 .haibao-fa{
  // border: 1px solid blue;
   width: 100vw;
   height: 60vw;
   top:-1px;
   left: -0.5rem;
   position: absolute;
   overflow: hidden;
 }
 .haibao {
   display: block;
   width: 100vw;
   height: 100vh;
   position: absolute;
   top:calc(-32vh);
   // opacity: 0.8;
 }

 .fade-out {
   opacity: 0;
 }
 .pause-btn{
  background-color: rgb(150, 150, 150);
  opacity: 0.8;
  border-radius: 80px;
  height: 80px;
  width: 80px;
  top:calc(50% - 40px);
  left: calc(50% - 30px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: aliceblue;
 }
 .pause-btn-xn{
  background-color: rgb(150, 150, 150);
  opacity: 0.8;
  border-radius: 80px;
  height: 80px;
  width: 80px;
  top: 50vw;
  left: calc(50% - 30px);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: aliceblue;
 }
 .pause-btn2{
  background-color: rgba(150, 150, 150, 0);
  font-size: 28px;
  color:  aliceblue;
  bottom: 10px;
  position: absolute;
  right: 10px;
  opacity: 0.5;
 }
}
</style>