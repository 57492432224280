<template>
 <div class="xn-fa">
  <div ref="yao" class="border-yao"></div>
  <div class="fa" :class="{'overh':videoState}">
   <transition name="van-slide-right">
    <!-- <div class="xn-info" v-show="!videoState"> -->
     <van-image v-show="!videoState" class="xn-info xnjy-cla"  src="./xinniangkuang.png" />
    <!-- </div> -->
   </transition>
   <transition name="van-fade">
      <BoxVideo v-show="videoState" ref="video_ref" :videoState.sync="videoStateF" src="./jxxmv1.mp4" @stopFn="(e)=>{videoState=false;$emit('update:qqshow',false)}" @qqshowFn="$listeners.qqshowFn" :video-data="{currentTime:0}"></BoxVideo>
   </transition>
  </div>
  <div>
   <transition name="van-fade">
      <van-image v-show="!videoState" class="wxz1-cla"  src="./jxx1.png" />
   </transition>
   </div>
 </div>
 
</template>

<script>
import BoxVideo from './BoxVideo.vue'
export default {
 components:{BoxVideo},
  props:{
   visible_829:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
     videoState:true,
     videoStateF:false,
    }
  },
  watch:{
   // visible_829(v){
   //  if(v){
   //    this.$refs.video_ref.operateVideoTime(0,1)
   //  }
   // }
  },
  methods:{
   toastFn(){
    this.$toast({
       message: '当然还有',
       icon: './huadongtishi.png',
       duration:2000,
       closeOnClick:true,
       closeOnClickOverlay:true,
     });
   }
  }
}
</script>


<style scoped>
@keyframes rotate {
     0% {transform: rotate(0deg);}
     25% {transform: rotate(-5deg);}
     /* 50% {transform: rotate(0);} */
     75% {transform: rotate(5deg);}
     100% {transform: rotate(0deg);}
 }
.xn-fa{
  height:115vw;
}
.border-yao{
 animation: rotate 2.1s linear infinite;
 border: 1.5vw dashed rgb(255, 241, 210);
 border-radius: 3rem 0  3rem 0;
 z-index: -1;
 height: 115vw;
 width: 80vw;
}
.fa{
  transform: translateY(-117.5vw);
  border: 1.5vw dashed rgba(255, 241, 210,0);
  height:115vw;
  width: 80vw;
  display: flex;
  flex-direction: column;
  border-radius: 3rem 0  3rem 0;
}
.overh{
 overflow: hidden;
}
.wxz1-cla{
 /* position: relative; */
 transform: translateY(-300vw);
 /* top: -73vh; */
 left: -50vw;
 width: 130vw;
 object-fit:cover;
 z-index: 100;
}
.xn-info{
 align-self: self-end;
 
 /* border: 1px solid blue; */
}
.xnjy-cla{
 width: 65vw;
 height: 50vw;
 left: 8vw;
 top: 1vh;
}
</style>