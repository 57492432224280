<template>
 <div class="fa">
  <!-- <van-image class="wxz1-cla"  src="./liucheng.png" /> -->
  <div class="bgimg">
   <!--  -->
    <div class="btn-1" :class="{'dianjitishi':dianjitishi1}" @click="()=>{showWindowInfo(marker,'AM5:18出发');if(dianjitishi1){dianjitishi1=false;dianjitishi2=true}}"></div>
    <div class="btn-2" :class="{'dianjitishi':dianjitishi2}" @click="()=>{showWindowInfo(marker1,'AM6:18接亲');closeAll()}"></div>
    <div class="btn-3" :class="{'dianjitishi':dianjitishi3}" @click="()=>{showWindowInfo(marker2,'AM9:18婚房');closeAll()}"></div>
    <div class="btn-4" :class="{'dianjitishi':dianjitishi4}" @click="()=>{showWindowInfo(marker3,'AM11:18酒宴-福满园农家乐');closeAll()}"></div>
  </div>
  <div ref="baiduMap" id="container"></div> 
 </div>
</template>
<script>

export default {
  props:{
    visible_356:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
     map:null,
     marker:null,
     marker1:null,
     marker2:null,
     marker3:null,
     label:null,
     dianjitishiall:false,
     dianjitishi1:true,
     dianjitishi2:false,
     dianjitishi3:false,
     dianjitishi4:false,
    }
  },
  mounted(){
   this.init()
  },
  methods:{
   closeAll(){
    // this.dianjitishiall = false
    this.dianjitishi1 = false
    this.dianjitishi2 = false
    this.dianjitishi3 = false
    this.dianjitishi4 = false
   },
   createDOM() {
    var img = document.createElement('img');
    img.style.height = '60px';
    img.style.width = '90px';
    img.src = 'mappoint.png';
    img.draggable = false;
    return img;
   },
   init(){
    this.map = new window.BMapGL.Map('container')
    var point = new window.BMapGL.Point(124.259663,40.126436)
    var icon = new window.BMapGL.Icon("mappoint.png",new window.BMapGL.Size(100,50),{anchor:new window.BMapGL.Size(-10,0)})
    this.marker = new window.BMapGL.Marker(point,{icon:icon}); 
    this.marker1 = new window.BMapGL.Marker(new window.BMapGL.Point(124.169518,40.124622),{icon:icon}); 
    this.marker2 = new window.BMapGL.Marker(new window.BMapGL.Point(124.163607,39.86305),{icon:icon}); 
    this.marker3 = new window.BMapGL.Marker(new window.BMapGL.Point(124.308189,40.115779),{icon:icon}); 
    var customOverlay = new window.BMapGL.CustomOverlay(this.createDOM, {point: point,offsetY: -10})
    var customOverlay1 = new window.BMapGL.CustomOverlay(this.createDOM, {point: new window.BMapGL.Point(124.169518,40.124622),offsetY: -10})
    var customOverlay2 = new window.BMapGL.CustomOverlay(this.createDOM, {point: new window.BMapGL.Point(124.163607,39.86305),offsetY: -10})
    var customOverlay3 = new window.BMapGL.CustomOverlay(this.createDOM, {point: new window.BMapGL.Point(124.308189,40.115779),offsetY: -10})
    this.map.addOverlay(customOverlay)
    this.map.addOverlay(customOverlay1)
    this.map.addOverlay(customOverlay2)
    this.map.addOverlay(customOverlay3)
    this.map.centerAndZoom(this.marker3.getPosition(), 14)
    this.map.setMinZoom(10)
    this.map.setMaxZoom(18)
    var bounds =  new window.BMapGL.Bounds(new window.BMapGL.Point(123.759451,39.863753) , new window.BMapGL.Point(124.37806,40.261338) )
    this.map.enableScrollWheelZoom(true)
    this.label = new window.BMapGL.Label('con', {       // 创建文本标注
    offset: new window.BMapGL.Size(-40, 0)           // 设置标注的偏移量
    })
    this.map.addOverlay(this.label);
    this.label.setStyle({
           fontSize: '14px',
           border: '1px solid rgb(135, 210, 134)',
           borderRadius:'5px',
           padding:'5px 8px',
           fontFamily: "Liuhuan"
         })
     this.showWindowInfo(this.marker3,'AM11:18酒宴-福满园农家乐')
     customOverlay.addEventListener("click", ()=>{   
        this.showWindowInfo(this.marker,'AM5:18出发') 
       })
     customOverlay1.addEventListener("click", ()=>{   
        this.showWindowInfo(this.marker1,'AM6:18接亲') 
       })
     customOverlay2.addEventListener("click", ()=>{   
        this.showWindowInfo(this.marker2,'AM9:18婚房') 
       })
     customOverlay3.addEventListener("click", ()=>{   
        this.showWindowInfo(this.marker3,'AM11:18酒宴-福满园农家乐') 
       })
     this.map.addEventListener("dragend", ()=> {
           if (bounds.containsPoint(this.map.getCenter())) return;
              // this.map.panTo(this.marker.getPosition());
              this.map.flyTo(this.marker3.getPosition() , 14)
             })
   },
   showWindowInfo(marker,con){
    // this.map.panTo(marker.getPosition());
    this.map.flyTo(marker.getPosition() , 14)
    this.label.setPosition(marker.getPosition())
    this.label.setContent(con)
    this.label.show();
   }
  }
}
</script>


<style scoped>
.fa{
 padding: 5vw;
}
#container{
 width: 90vw;
 height: 55vw;
 color: rgb(135, 210, 134);
 border-radius: 0px;
}
.bgimg{
 box-sizing: border-box;
  background: url("/public/liucheng.png") no-repeat;
  height: 85vw;
  width: 90vw;
  overflow: hidden;
  background-position:center;
  background-size:75vw 100%;
}
.btn-1{
 /* border:1px solid blue; */
 position: relative;
 width:25vw;
 height: 20vw;
 top:6vw;
 left: 15vw;

}
.dianjitishi{
 background: url("/public/dianji.png") no-repeat;
 background-position:bottom;
 background-size:18vw 80%;
 opacity: 0.7;
}
.btn-2{
 /* border:1px solid blue; */
 position: relative;
 width:25vw;
 height: 20vw;
 top: -2vw;
 left: 50vw;
}
.btn-3{
 /* border:1px solid blue; */
 position: relative;
 width:25vw;
 height: 20vw;
 top:-7vw;
 left: 15vw;
}
.btn-4{
 /* border:1px solid blue; */
 position: relative;
 width:25vw;
 height: 20vw;
 top:-13vw;
 left: 50vw;
}
</style>