<template>
 <div>
  <van-image class="wxz1-cla"  src="./zhufu1.jpg" />
  <van-image class="wxz1-cla"  src="./zhufu2.jpg" />
 </div>
 
</template>

<script>
export default {
  props:{
    visible_356:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
     
    }
  }
}
</script>


<style scoped>
.wxz1-cla{
padding: 0;
margin-top: -5px;
}
</style>