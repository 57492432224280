import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant'

import 'vant/lib/index.css'
import '@/assets/font/fontdjs.css'
import '@/assets/font2/fontdjs.css'
import wxjssdk from 'jweixin-module'
Vue.prototype.$wx = wxjssdk
Vue.use(Vant);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
