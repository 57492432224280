<template>
 <div class="box" :class="'bcimg'+bcimg" :style="{width:qqW+'rem',height:qqW*771/600+'rem'}" ref="packet" v-show="hidden"></div>
</template>

<script>
const {Animation, Clip} = require('chito')
export default {
 name: "bonusRain",
 data() {
   return {
     bcimg:'',
     qqW:6,
     // 代表红包该显示还是不显示
     hidden: false,
     // 用于存放chito生成的动画实例
     animation: null,
     // 用于存放从父组件传进来的配置项
     options: null,
     // 用于记录红包是不是已经掉落
     isDropped: false,
     // 用于判断红包在掉落的时候是顺时针旋转还是逆时针旋转
     rotateComputed: 1,
     initX:0
   }
 },
 methods: {
   show(obj) {
     // 把传进来的配置项赋值给options
     this.options = obj
     // 自定义了一个beforShow的钩子，这样动画在初始化的时候想执行什么方法也方便
     // if(this.options.beforeShow){
     //   this.options.beforeShow()
     // }

     // 如果穿进来了一个封面图片，就替换掉当前的红包封面
     // if(obj.cover){
     //   this.$refs['packet'].style.background = `url(${obj.cover})`
     //   this.$refs['packet'].style.backgroundSize = 'contain'
     //   this.$refs['packet'].style.backgroundRepeat = 'no-repeat'
     // }

     // 创建一个动画剪辑
     let clip = new Clip({
       // 剪辑持续的时间，如果掉落距离是固定的，那么掉落时间就决定了红包掉落的速度
       duration: Math.round(Math.random()*(6000-1500)+1500),
       // 剪辑重复一次，因为每个红包都是独立的
       repeat: 1
     }, {
       // 掉落的路线，从屏幕高度+100 开始到 -200
       y: [-200,document.documentElement.clientHeight+100]
     })

     // 红包在掉落的过程中，每一次运动都会触发clip的 update 事件，
     // 如果你需要一些花里胡哨的效果，可以在这里定义
     clip.on('update', (ev) => {
       var keyframe = ev.keyframe;
       // 因为要操作dom 所以需要用nextTick
       this.$nextTick(() => {
         // 掉落的过程中动态改变红包的y轴位置this.$refs['packet'].getBoundingClientRect().y
         // this.$refs['packet'].style.bottom = (this.$refs['packet'].style.bottom + 1)+ 'px';
         this.$refs['packet'].style.bottom = keyframe.y + 'px';
         // 根据配置项的x轴位置来设置红包的x轴位置
         this.$refs['packet'].style.left = this.initX + 'px'
         // 红包掉落的时候让它旋转起来ev.progress this.rotateComputed
         this.$refs['packet'].style.transform = `rotate(` + (keyframe.y * this.rotateComputed) +`deg)`
       })
     });
     // 创建Animation实例
     this.animation = new Animation();
     // 把创建的剪辑添加到Animation实例中
     this.animation.addClip([clip]);
   },
   start(){
     this.rotateComputed = Math.random()-0.5
     this.bcimg = Math.round(Math.random()*(5-1)+1);
     this.qqW = Math.random()*(9-6)+6;
     this.initX = Math.round(Math.random()*(document.documentElement.clientWidth+100)-100)
     // 让动画开始播放
     this.animation.start()
     this.hidden = true
     // 动画完成播放的事件
     this.animation.on('complete', () => {
       // alert(12465)
       // 动画结束之后回调onDropped事件
       if (this.isDropped === false) {
         // this.options.onDropped()

         // 动画完成后，把isDropped的值修改为是
         // 代表红包已经掉落，理论上没什么用，但是玩意需要父组件做判断呢？
         this.isDropped = true
       }
       // 动画播放完成后，隐藏红包
       this.hidden = false
     })
   }
 }
}
</script>

<style scoped lang="stylus">
* {
 user-select: none
 outline none
}

.box {
 position absolute
 bottom -10rem
 background-repeat no-repeat
 background-size: 100% 100%
 background-position: center
 transform rotate(0deg)
 z-index 600
}
.bcimg1{
 background-image url("/public/qq1.png")
}
.bcimg5{
 background-image url("/public/qq5.png")
}
.bcimg2{
 background-image url("/public/qq2.png")
}
.bcimg3{
 background-image url("/public/qq3.png")
}
.bcimg4{
 background-image url("/public/qq4.png")
}

</style>
