<template>
 <!-- -->
  <div class="hello" :style="{overflowY:qqshow?'hidden':'scroll'}" ref="scrollview" @click.stop="playmv" @scroll="scrollChange($event)" >
   <div ref="reffatop" class='haibao-fa'>
    <!--   -->
    <BoxVideo ref="video_ref" :fatop="fatop" :once.sync="once" :full="true" @stopEndFn="vlogStopEndFn" :muted="false" class="vlog" src="./vlog.mp4" :videoState.sync="videoState" :video-data="{currentTime:0}"></BoxVideo>
    <transition name="van-fade">
     <van-image class="down-cla" v-show="!visible_356" src="./down.png" />
    </transition>
   </div>
   <van-image style="width:90vw;margin:5vw;" src="./fenge1.gif" />
   <XinLang ref="xl" class="xl-fa" :visible_356="visible_500" />
   <XinNiang ref="xn" class="xn-fa" :qqshow.sync="qqshow" @qqshowFn="qqshowFn" :visible_829="visible_829" />
   <ZhuFu class="zhufu-cla" />
   <LastEnd ref="LastEnd" />
   <div v-show="visible_500" size="small" @click="goTop" class="go-top">
    <van-image src="./gotop.png" />
   </div>
   <div v-show="visible_260" size="small"  @click="musicop" class="music-class">
    <van-icon name="music"  :class="{'roll':videoState}" />
   </div>
   <van-popup v-model="loadingmsg" round  :style="{ padding: '20px' }">
    <div style="transform:translateY(38px);height: 0;">加载中...</div>
    <van-circle
       v-model="c"
       color="rgb(135, 210, 134)"
       :rate="100"
       :speed="70"
      />
   </van-popup>
 </div>
</template>

<script>
import BoxVideo from './BoxVideo.vue'
import XinLang from './newP1.vue'
import XinNiang from './newP2.vue'
import ZhuFu from './BlessingShare.vue'
import LastEnd from './LastEnd.vue'
// import DongHua from './DongHua.vue'
import showPacket from './showPacket.js'
export default {
 components:{BoxVideo,XinLang,XinNiang,ZhuFu,LastEnd},
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
     return {
        instanceList:[],
        c:0,
        qqshow:false,
        once:true,
        qqshowonce:true,
        fatop:true,
        timeV:null,
        loadingmsg:true,
        videoState:false,
        gotopshow:false,
        visible_829:false,
        visible_500:false,
        visible_356:false,
        visible_260:false,
        activeName: '1',
        time: 30 * 60 * 60 * 1000,
       }
  },
  watch:{
   // visible_829(n){
   //  if(n){
   //   this.instanceList.map((a)=>{
   //    a.start()
   //   })
   //  }
   // }
  },
  mounted(){
    let mulitImg = [
    './haibao.jpg',
    './wxz1.png',
    './jxx1.png'
    ]
    let promiseAll = [], img = [], imgTotal = mulitImg.length;
    for(let i = 0 ; i < imgTotal ; i++){
        promiseAll[i] = new Promise((resolve)=>{
            img[i] = new Image()
            img[i].src = mulitImg[i]
            img[i].onload = function(){
                 resolve(img[i])
            }
        })
    }
    promiseAll[imgTotal] = new Promise((resolve)=>{
      this.timeV = setInterval(()=>{
       console.log(this.$refs.video_ref.canplay);
       if(this.$refs.video_ref.canplay){
        resolve()
       }
      },10)
     })
    Promise.all(promiseAll).then(()=>{
     clearInterval(this.timeV)
     this.loadingmsg=false
    })
    for(let i=0;i<(Math.random()*10+20);i++){
         let a = showPacket()
         a.show()
         this.instanceList.push(a)
     }

  },
  beforeDestroy () {},
  methods: {
   qqshowFn(){
    this.qqshowonce = false
    this.instanceList.map((a)=>{
      a.start()
     })
     this.qqshow = false
   },
   playmv(){
    this.$refs.video_ref.playOnce()
   },
    musicop(){
      this.$refs.video_ref.operateVideo()
      // this.videoState = !this.videoState
    },
    vlogStopEndFn(){
     this.$refs.video_ref.operateVideoTime(39)
    },
   goTop(){
    if(this.$refs.scrollview.scrollTop>2){
     const timeTop = setInterval(()=>{
      let top = this.$refs.scrollview.scrollTop * 0.85
        this.$refs.scrollview.scrollTo(0,top)
        if(top<=2){
           this.$refs.scrollview.scrollTo(0,0)
           clearInterval(timeTop)
           location.reload();
           this.loadingmsg=false
          }
     },20)
     
    }else{
     this.$refs.scrollview.scrollTo(0,0)
    }
    
   },
    // 滚动监听
    scrollChange () {
      // console.log(this.$refs.scrollview.scrollTop)
      // console.log(this.$refs.LastEnd.$refs.LastEndFa.getBoundingClientRect().top)
      // console.log(this.$refs.reffatop.getBoundingClientRect().top);

    // console.log(this.$refs.xn.$refs.yao.getBoundingClientRect().top);
      if(this.$refs.xn.$refs.yao.getBoundingClientRect().top<70){
        if(this.qqshowonce){
               this.qqshow = true
            }
         }
      if(this.$refs.reffatop.getBoundingClientRect().top<-150){
         this.fatop = false
      }
      if(this.$refs.LastEnd.$refs.LastEndFa.getBoundingClientRect().top<0 && this.videoState && this.$refs.video_ref.studyTime.currentTime<93){
           this.$refs.video_ref.operateVideoTime(93)
      }
      
      // getBoundingClientRect
      // console.log(this.$refs.LastEnd.$refs.BaiduMap.$refs.baiduMap.getBoundingClientRect().top)
      // if(this.$refs.LastEnd.$refs.BaiduMap.$refs.baiduMap.getBoundingClientRect().top<this.$refs.scrollview.clientHeight){ // 地图出现
      //  // this.$refs.LastEnd.$refs.BaiduMap.dianjitishiall = true
      // }
      if(this.$refs.scrollview.scrollTop>500){
        this.visible_500 = true
      }
      if(this.$refs.scrollview.scrollTop<400){
        this.visible_500 = false
      }
      if(this.$refs.scrollview.scrollTop>356){
        this.visible_356 = true
      }
      if(this.$refs.scrollview.scrollTop>829){
        this.visible_829 = true
      }
      this.visible_260 = this.$refs.scrollview.scrollTop>260
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 @keyframes changeleft{
  0%{-webkit-transform:rotate(0deg);}
  50%{-webkit-transform:rotate(180deg);}
  100%{-webkit-transform:rotate(360deg);}
   }
.hello{
height: 100vh;
overflow-y: scroll;
overflow-x: hidden;
font-family: "Liuhuan";
color: rgb(135, 210, 134);
/* background: rgba(88, 206, 84, 0.5); */
}
/* .qqshow{
 overflow-y:hidden
} */
/* >>> .van-circle__text{
 color: rgb(135, 210, 134);
} */
.go-top{
 position: fixed;
 padding: 0;
 width: 34px;
 height: 34px;
 /* border: 2px solid rgb(135, 210, 134); */
 border-radius: 34px;
 bottom: 1rem;
 right: 1rem;
 color:rgb(135, 210, 134);
 z-index: 100;
 /* background-color: #fff; */
 opacity:0.6
}
.music-class{
 position: fixed;
 box-sizing: border-box;
 padding: 0;
 width: 32px;
 height: 32px;
 border-radius: 32px;
 right: 1rem;
 top: 1rem;
 color:rgb(135, 210, 134);
 z-index: 100;
 font-size: 34px;
 /* background-color: rgb(0, 0, 0); */
 opacity:0.8
}
.haibao-fa{
 height: 100vh;
 width: 100vw;
 /* margin-left: -50%; */
 /* padding: 30vh 1rem; */
 background-image: url("/public/haibao.jpg");
 background-size: 100% 100%;
}
/* .haibao-img{
   width: 100vw;
   height: 100vh;
   position: relative;
   opacity: 0;
} */
.vlog{
  transform: translateY(32vh);
  margin: 0 .5rem;
  /* border:1px solid blue */
}

.roll{
  animation:changeleft 6s linear infinite;
}
.xn-fa{
 width: 80vw;
 margin: 10vw;
 }
.down-cla{
 position: absolute;
 width: 40vw;
 bottom:0;
 left:30vw;
 z-index: 100;
}
.zhufu-cla{
 margin-top: 10px;
}
</style>
